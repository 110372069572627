import {
  required,
  requiredIf,
  minLength,
  sameAs,
} from 'vuelidate/lib/validators';

import VueI18n from '@/i18n';

export default {
  validations() {

    return {
      userData: {
        full_name: {
          required,
        },
        date_of_birth: {
          required: requiredIf(() => {
            return this.getSelectedCommissionMember?.name === 'Commissioner';
          }),
        },
        term_of_office_start: {
          required: requiredIf(() => {
            return this.getSelectedCommissionMember?.name === 'Commissioner';
          }),
        },
        term_of_office_end: {
          required: requiredIf(() => {
            return this.getSelectedCommissionMember?.name === 'Commissioner';
          }),
        },
        work_place: {
          required: requiredIf(() => {
            return this.getSelectedCommissionMember?.name === 'Commissioner';
          }),
        },
        identification_number: {
          required: requiredIf(() => {
            return this.getSelectedCommissionMember?.name === 'Commissioner';
          }),
          minLength: minLength(6),
        },
        department: {
          required,
        },
        position: {
          required,
        },
        working_phone_number: {
          required,
        },
        working_email: {
          required,
        },
      },
    };
  },
};
