<template>
  <div class="profile__wrapper">
    <div
      v-for="group in getModel.group"
      :key="group.name"
      class="profile__block"
    >
      <h4 class="title-h4 profile__title">
        {{ $t(`profile.${group.name}`) }}
      </h4>
      <div class="profile__grid">
        <div
          v-for="item in group.fields"
          :key="item.name"
          :class="`${item.column_type}`"
        >
          <div
            :class="{ 'required_date-picker': isCreateUser }"
            v-if="item.type === 'date'"
          >
            <DatePicker
              v-if="item.name === 'term_of_office_start'"
              :format="'DD-MM-YYYY'"
              value-type="YYYY-MM-DD HH:mm:ss"
              :lang="getDateConfig"
              v-model="userData[item.name]"
              :placeholder="$t(`profile.${item.name}`)"
              :disabled="!isEditable"
              :class="{
                'is-error': $v.userData[item.name].$error,
              }"
            />
            <DatePicker
              v-else-if="item.name === 'term_of_office_end'"
              :format="'DD-MM-YYYY'"
              value-type="YYYY-MM-DD HH:mm:ss"
              :lang="getDateConfig"
              v-model="userData[item.name]"
              :placeholder="$t(`profile.${item.name}`)"
              :disabled="!isEditable || !userData.term_of_office_start"
              :disabled-date="disabledDatesEnd"
              :class="{
                'is-error': $v.userData[item.name].$error,
              }"
            />
            <DatePicker
              v-else
              :format="'DD-MM-YYYY'"
              value-type="YYYY-MM-DD HH:mm:ss"
              :lang="getDateConfig"
              v-model="userData[item.name]"
              :placeholder="$t(`profile.${item.name}`)"
              :disabled="!isEditable"
              :class="{
                'is-error': $v.userData[item.name].$error,
              }"
            />
          </div>

          <text-field
            v-else-if="item.name === 'identification_number'"
            :id="item.name"
            v-model="userData[item.name]"
            placeholder="-"
            :title="$t(`profile.${item.name}`)"
            :value-mask="item.mask"
            :is-required="isCreateUser"
            :type="item.type"
            :is-disabled="!isEditable || isPresiding"
            :errorText="getErrorText"
            :is-error="
              !!$v.userData[item.name] && $v.userData[item.name].$error
            "
          />
          <text-field
            v-else
            :id="item.name"
            v-model="userData[item.name]"
            placeholder="-"
            :title="$t(`profile.${item.name}`)"
            :value-mask="item.mask"
            :is-required="isCreateUser"
            :type="item.type"
            :is-disabled="!isEditable || isPresiding"
            :is-error="
              !!$v.userData[item.name] && $v.userData[item.name].$error
            "
          />
        </div>
      </div>
    </div>

    <div class="" v-if="isCreateUser">
      <CreatePasswordForm
        :initValidation="initValidation"
        @resetValid="initValidation = false"
        @credentials="(data) => (credentials = data)"
      />
    </div>

    <div class="no-print">
      <div class="control__btn-wrapper">
        <Button
          transparent
          @click.native="
            $router.push({ name: 'access-administrator_commissioner-list' })
          "
        >
          {{ $t('buttons.back_to_list') }}
        </Button>
        <Button
          icon-name="arrow"
          isRightIcon
          v-if="!isCreateUser && isEditable && !isPresiding"
          @click.native="saveUserHandler"
        >
          {{ $t('buttons.save') }}
        </Button>
        <Button
          icon-name="arrow"
          isRightIcon
          v-if="isCreateUser"
          @click.native="saveUserHandler"
        >
          {{ $t('buttons.user_create') }}
        </Button>
        <Button
          icon-name="print"
          isRightIcon
          @click.native="print"
          v-if="!isCreateUser"
        >
          {{ $t('buttons.print') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import validate from '@/mixins/createUserByAccessAdminvalidationMixin';
import commissioner_profile from '@/models/access_administrator/commissioner_profile';
import profile from '@/models/access_administrator/profile';
import datePickerConfig from '@/library/date-picker-config';
import DatePicker from 'vue2-datepicker';
import CreatePasswordForm from '@/elements/CreatePasswordForm.vue';
export default {
  components: {
    DatePicker,
    CreatePasswordForm,
  },
  mixins: [validate],
  data() {
    return {
      userData: {},
      initValidation: false,
      isValidForm: false,
      credentials: null,
    };
  },

  async created() {
    if (!!!this.$route.params?.activity) {
      this.$router.push({ name: 'access-administrator_commissioner-list' });
    } else {
      if (!this.isCreateUser) {
        await this.$store.dispatch('getUserAccessAdmin', this.$route.params.id);
        await this.setUserData();
      }
    }
  },

  mounted() {
    this.setCurrentnData();
  },

  computed: {
    ...mapGetters(['getSelectedCommissionMember', 'getUserDetailsAccessAdmin']),

    getModel() {
      if (this.getSelectedCommissionMember.name === 'Commissioner') {
        return commissioner_profile;
      } else {
        return profile;
      }
    },

    getDateConfig() {
      return datePickerConfig;
    },

    isCreateUser() {
      return this.$route.params.activity === 'create';
    },

    isEditable() {
      return this.$route.params.activity !== 'readonly';
    },

    isPresiding() {
      return this.getSelectedCommissionMember.name === 'Presiding';
    },

    isValidLength() {
      return this.$v.userData?.identification_number?.minLength;
    },

    getErrorText() {
      return !this.isValidLength ? this.$t('error.field_is_short') : '';
    },

    getDataForEdit() {
      let obj = {};
      for (let key in this.userData) {
        if (!!this.userData[key]) {
          obj[key] = this.userData[key];
        }
      }
      return obj;
    },

    createHandlerName() {
      return `create${this.getSelectedCommissionMember.name}AccessAdmin`;
    },
    updateHandlerName() {
      return `update${this.getSelectedCommissionMember.name}AccessAdmin`;
    },
  },

  methods: {
    setCurrentnData() {
      this.getModel?.group.map((g) =>
        g.fields.map((f) => {
          this.$set(this.userData, f.name, '');
        })
      );
    },

    setUserData() {
      let data = this.getUserDetailsAccessAdmin?.role;
      if (!!data) {
        for (let key in this.userData) {
          let val = !!data[key] ? data[key] : '-';
          this.userData[key] = val;
        }
        this.userData.term_of_office_start = `${this.userData.term_of_office_start} 00:00:00`;
        this.userData.term_of_office_end = `${this.userData.term_of_office_end} 00:00:00`;
        this.userData.date_of_birth = `${this.userData.date_of_birth} 00:00:00`;
      }
    },

    disabledDatesEnd(date) {
      return (
        new Date(date).getTime() <=
        new Date(this.userData?.term_of_office_start).getTime()
      );
    },

    saveUserHandler() {
      if (this.isCreateUser) {
        this.$v.$touch();
        this.initValidation = true;

        this.$nextTick(() => {
          if (!this.$v.$invalid && !!this.credentials) {
            const { email, password } = this.credentials;
            let payload = {
              ...this.userData,
              email: email,
              password: password,
            };
            this.$store.dispatch(this.createHandlerName, payload);
          }
        });
      } else {
        let payload = {
          ...this.getDataForEdit,
          uuid: this.getUserDetailsAccessAdmin.uuid,
        };
        this.$store.dispatch(this.updateHandlerName, { data: payload });
      }
    },

    print() {
      window.print();
    },
  },
};
</script>
